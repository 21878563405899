import Link from "next/link";

function Footer({ className }) {
  const links_1 = [
    { name: "Klantenportaal", url: "/klantenportaal" },
    { name: "Bestellen", url: "/bestellen" },
    { name: "Betalen", url: "/betalen" },
    { name: "Bezorgen", url: "/bezorgen" },
    { name: "Assortiment in Raalte", url: "/assortiment-raalte" },
    { name: "Ik ben particulier", url: "/ik-ben-particulier" },
    { name: "Privacy Policy", url: "/privacy-policy" },
    { name: "Algemene voorwaarden", url: "/algemene-voorwaarden" },
  ];
  const links_2 = [
    { name: "Projecten", url: "/projecten" },
    { name: "Onze klanten", url: "/onze-klanten" },
    { name: "Openingstijden", url: "/openingstijden" },
    { name: "Bezoekadres & Contact", url: "/contact" },
    {
      name: "Rhododendron kwekerij",
      url: "https://rhododendronkweker.nl/",
      target: "_blank",
    },
    { name: "Keurmerk Planetproof", url: "/keurmerk-planetproof" },
    {
      name: "Vacatures bij Olaf Nijenkamp",
      url: "/vacatures",
    },
    { name: "BBL Stage", url: "/bbl-stage" },
  ];
  const certifications = [
    {
      url: "https://www.planetproof.eu/",
      image: "/assets/logo/certifications/planet-proof.svg",
    },
    {
      url: "https://my-mps.com/",
      image: "/assets/logo/certifications/MPS_logo.svg",
    },
    {
      url: "https://www.milieukeur.nl/",
      image: "/assets/logo/certifications/MK_FC_CMYK.svg",
    },
    {
      url: "https://groenkeur.nl/",
      image: "/assets/logo/certifications/logo-gk.svg",
    },
    {
      url: "https://nlgreenlabel.nl/",
      image:
        "/assets/logo/certifications/Logo-NL-Greenlabel-met-naam_zonder-achtergrond.png",
    },
  ];
  return (
    <footer className={`w-full flex flex-col mt-auto pt-6 ${className}`}>
      <img
        src="/assets/visuals/gras-1.svg"
        alt="Gras"
        width="100"
        height="30"
        className="block w-full h-auto transform translate-y-1 lg:hidden"
      />
      <img
        src="/assets/visuals/gras-desktop.svg"
        alt="Gras"
        width="100"
        height="30"
        className="hidden w-full h-auto transform translate-y-1 lg:block"
      />
      <div className="w-full py-2 lg:py-4 bg-olaf-green-700 flex flex-col">
        <div className="olafGrid grid grid-cols-1 md:grid-cols-4 gap-y-8 md:gap-x-20 text-white">
          <div>
            <p className="mb-2 md:mb-6">
              <strong>In het kort</strong>
            </p>
            <p className="text-base !mt-0">
              Olaf Nijenkamp Groothandel Tuinplanten BV levert het complete
              assortiment bomen en planten. Dat doen we al 30 jaar voor
              hoveniers, grootgroen en overheid.
            </p>
            <p className="text-base">
              Onze succesformule? Elke dag alles in het werk zetten voor de
              allermooiste bomen en planten en tevreden klanten!
            </p>
          </div>
          <div>
            <p className="mb-2 md:mb-6">
              <strong>Klantenservice</strong>
            </p>
            <ul className="space-y-2">
              {links_1.map(({ name, url }) => (
                <li key={url}>
                  <Link legacyBehavior href={url}>
                    <a className="text-base">{name}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="mb-2 md:mb-6">
              <strong>Over ons</strong>
            </p>
            <ul className="space-y-2">
              {links_2.map(({ name, url, target = "" }) => (
                <li key={url}>
                  <Link legacyBehavior href={url}>
                    <a className="text-base" target={target}>
                      {name}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col">
            <p className="mb-2 md:mb-6">
              <strong>Contact</strong>
            </p>
            <p className="text-base !mt-0">
              Olaf Nijenkamp
              <br />
              Almelosestraat 88
              <br />
              8102 HE Raalte
            </p>
            <Link legacyBehavior href="tel:0572-352131">
              <a className="text-base underline">0572-352131</a>
            </Link>
            <Link legacyBehavior href="/">
              <a className="text-base underline">www.olaf-nijenkamp.nl</a>
            </Link>
            <Link legacyBehavior href="mailto:verkoop@olaf-nijenkamp.nl">
              <a className="text-base underline">verkoop@olaf-nijenkamp.nl</a>
            </Link>
            <div className="flex flex-col mt-4">
              <p>
                <strong>Volg ons op</strong>
              </p>
              <div className="flex space-x-2 mt-2">
                <Link
                  legacyBehavior
                  href="https://www.facebook.com/OlafNijenkampBV/"
                >
                  <a
                    className="group w-8 h-8 bg-olaf-green-400 hover:bg-olaf-green-500 rounded-sm flex items-center justify-center"
                    rel="noopener"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="w-3/5 h-3/5 object-contain fill-olaf-green-700 group-hover:fill-olaf-green-400"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg>
                  </a>
                </Link>
                <Link legacyBehavior href="https://twitter.com/olafnijenkamp">
                  <a
                    className="group w-8 h-8 bg-olaf-green-400 hover:bg-olaf-green-500 rounded-sm flex items-center justify-center"
                    rel="noopener"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-3/5 h-3/5 object-contain fill-olaf-green-700 group-hover:fill-olaf-green-400"
                    >
                      <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                    </svg>
                  </a>
                </Link>
                <Link
                  legacyBehavior
                  href="https://www.linkedin.com/company/olaf-nijenkamp-groothandel-tuinplanten-bv/"
                >
                  <a
                    className="group w-8 h-8 bg-olaf-green-400 hover:bg-olaf-green-500 rounded-sm flex items-center justify-center"
                    rel="noopener"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className="w-3/5 h-3/5 object-contain fill-olaf-green-700 group-hover:fill-olaf-green-400"
                    >
                      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                    </svg>
                  </a>
                </Link>
                <Link
                  legacyBehavior
                  href="https://www.youtube.com/channel/UCciDXl8S81EtLFc3vVGS98g/featured"
                >
                  <a
                    className="group w-8 h-8 bg-olaf-green-400 hover:bg-olaf-green-500 rounded-sm flex items-center justify-center"
                    rel="noopener"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      className="w-3/5 h-3/5 object-contain fill-olaf-green-700 group-hover:fill-olaf-green-400"
                    >
                      <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                    </svg>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="olafGrid flex flex-col items-start sm:flex-row sm:items-center justify-between !mt-8">
          <p className="text-white text-[9px] lg:text-sm">
            © Copyright {new Date().getFullYear()} Olaf Nijenkamp B.V.{" "}
          </p>
          <div className="flex space-x-8 mt-8 sm:mt-0">
            {certifications.map((cert, i) => (
              <Link legacyBehavior href={cert.url} key={i}>
                <a target="_blank" rel="noopener">
                  <img
                    src={cert.image}
                    alt="Planet Proof"
                    width="21"
                    height="21"
                    className="h-12 w-auto object-contain"
                  />
                </a>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
